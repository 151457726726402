import classNames from 'classnames';
import React from 'react';

import logo from 'images/logo.svg';

import Container from '../container';
import Link from '../link';

import leftShape from './images/left-shape.svg';
import rightShape from './images/right-shape.svg';

const navigation1 = {
  label: 'Company',
  items: [
    { href: '#services', name: 'Services' },
    { href: '#about-us', name: 'About us' },
    { href: '#technologies', name: 'Technologies' },
    { href: 'mailto:hello@arch.cloud', name: 'Contact Us' },
  ],
};
const navigation2 = {
  label: 'Related',
  items: [
    { href: 'https://konem.blog', name: 'Our Blog' },
    { href: 'https://awsplayground.1way2cloud.com/index.html', name: 'AWS Playground' },
    { href: 'https://terraforge.io/', name: 'Terraforge' },
  ],
};

// eslint-disable-next-line react/prop-types
const Footer = ({ className }) => (
  <footer className={classNames('overflow-hidden bg-black text-white', className)}>
    <h2 className="sr-only">Footer</h2>
    <div className="py-20 lg:py-28" id="contact-us">
      <Container size="md">
        <img
          className="absolute left-0 top-1/2 hidden h-auto w-52 -translate-y-1/2 sm:block md:-left-20 md:w-auto"
          src={leftShape}
          alt=""
          aria-hidden
        />
        <img
          className="absolute right-0 top-1/2 hidden w-52 -translate-y-1/2 sm:block md:-right-20 md:w-auto"
          src={rightShape}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col items-center text-center">
          <span className="text-2xl leading-none">Get in touch</span>
          <Link
            className="mt-10 bg-primary px-4 py-2.5 text-xl font-semibold uppercase leading-none tracking-widest text-black transition-colors duration-200 hover:bg-hover-primary md:text-2xl md:leading-none lg:text-3xl lg:leading-none xl:text-4xl xl:leading-none"
            to="mailto:hello@arch.cloud"
          >
            hello@arch.cloud
          </Link>
        </div>
      </Container>
    </div>
    <div className="border-t border-white border-opacity-10 py-12">
      <Container className="grid grid-cols-12 gap-8" size="md">
        <div className="col-span-12 flex flex-col justify-between lg:col-span-5">
          <Link to="/">
            <img src={logo} alt="Arch Cloud logo" width={115} height={12} />
          </Link>
          <span className="mt-8 text-sm leading-none">
            Creative design by{' '}
            <Link
              className="transition-colors duration-200 hover:text-primary"
              to="https://pixelpoint.io/"
              target="_blank"
              rel="noopener"
            >
              Pixel Point
            </Link>
          </span>
        </div>
        <div className="col-span-12 grid grid-cols-7 gap-8 text-xs font-semibold uppercase tracking-widest lg:col-span-7">
          <div className="col-span-7 sm:col-span-4 lg:col-span-5 xl:col-span-4">
            <h3 className="text-white text-opacity-30">{navigation1.label}</h3>
            <div className="mt-6 grid max-w-sm grid-cols-3 gap-x-8 gap-y-6 md:gap-x-12">
              {navigation1.items.map(({ href, name }) => (
                <Link
                  className="col-span-full leading-none transition-colors duration-200 hover:text-primary xs:col-span-1 xs:even:col-span-2"
                  to={href}
                  key={name}
                  onClick={(e) => {
                    if (href.startsWith('#')) {
                      e.preventDefault();
                      document.querySelector(href).scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-span-7 sm:col-span-3 lg:col-start-6 lg:col-end-8">
            <h3 className="text-white text-opacity-30">{navigation2.label}</h3>
            <div className="mt-6 grid gap-y-6">
              {navigation2.items.map(({ href, name }) => (
                <Link
                  className="leading-none transition-colors duration-200 hover:text-primary"
                  to={href}
                  key={name}
                  target="_blank"
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  </footer>
);

export default Footer;
